
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { Actions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import * as Yup from 'yup'

export default defineComponent({
  name: 'sign-in',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const submitButton = ref<HTMLButtonElement | null>(null)

    // Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label('Email'),
      password: Yup.string().min(4).required().label('Password')
    })

    // Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT)

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute('data-kt-indicator', 'on')
      }

      // Send login request
      // await store.dispatch(Actions.GET_CSRF_COOKIE)
      await store.dispatch(Actions.LOGIN, values)
      const [errorName] = Object.keys(store.getters.getErrors)
      const error = store.getters.getErrors[errorName]
      if (!error) {
        await router.push({ name: 'companies' })
      } else {
        Swal.fire({
          html: error[0],
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }

      // Deactivate indicator
      submitButton.value?.removeAttribute('data-kt-indicator')
      // eslint-disable-next-line
        if(submitButton.value){
            submitButton.value!.disabled = false
      }
    }

    return {
      onSubmitLogin,
      login,
      submitButton
    }
  }
})
